<template>
    <div class="recommend">
        <div class="listArea">
            <div class="item">
                <div class="wrapItem">
                    <div>
                        <img class="picA" :src="tjPic.a">
                    </div>
                    <div>
                        <div class="titleT">
                            <a target="_blank" href="https://apps.apple.com/cn/story/id1454206022">
                                <span class="">App Store精选故事：</span>
                                入门小语种，怎样才能轻松些？
                            </a>
                        </div>
                        <div class="contentT">
                            <div>
                                学外语可真是件麻烦事：日语同一个汉字有不同的发音，敬语复杂得不得了；
                                法语的名词分为阴性阳性，简直让人欲哭无泪；德语就更不用说了，名词竟然
                                有三种性别，单词与句子以长著称，连马克·吐温都忍不住吐槽……毫无规律，可能是它们共有的一种规律了。
                            </div>
                            <div>
                                然而不同语言对应不同文化，学习它们能帮助我们更好地理解日番韩剧、法餐西班牙建筑。
                                多掌握一种语言，就可以多看到一个世界。自学小语种总说不上容易，不过在《鹿老师说外语》里，
                                小语种的入门可以轻松有趣些<a target="_blank" href="https://apps.apple.com/cn/story/id1454206022"
                                                 class="quickLink">&gt;&gt;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem">
                    <div>
                        <img class="picA" :src="tjPic.b">
                    </div>
                    <div>
                        <div class="titleT">
                            <a target="_blank" href="https://mp.weixin.qq.com/s/fENNGMdxHJPU4tawUMCh8w">
                                <span class="">小米应用商店：</span>
                                鹿老师说外语|小语种学习必备神器！
                            </a>
                        </div>
                        <div class="contentT">
                            <div>
                                每种语言都是一种不同的世界观，是到达另一种文明的桥梁，是通往成功和创造无限可能的金钥匙。
                            </div>
                            <div>
                                2018年，继日语和俄语之后，教育部已经将法语、德语、西语正式列入高中课程。这意味着在未来职
                                业能力的竞争上，只掌握英语，已经赢面不大了。
                            </div>
                            <div>
                                掌握多个语种听起来都很美，但是做起来却非常难。怎么学，用什么学，去哪里学？但凡曾经尝试过
                                自学某个小语种的，都一定懂得这种痛：踌躇满志、报班买书、三天打渔两天晒网、最终无疾而终
                                <a target="_blank" href="https://mp.weixin.qq.com/s/fENNGMdxHJPU4tawUMCh8w"
                                   class="quickLink">&gt;&gt;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="item">
                <div class="wrapItem">
                    <div>
                        <img class="picA picC" :src="tjPic.c">
                    </div>
                    <div>
                        <div class="titleT">
                            <a target="_blank" href="https://www.wandoujia.com/award/blog/cn.lingodeer">
                                <span class="">第358期豌豆荚设计奖</span>
                            </a>
                        </div>
                        <div class="contentT">
                            <div>
                                俗话说，外语是了解别国文化的一把钥匙，但对于大多数朋友而言，从零开始学习的门槛真的很高，面对无从下手
                                的陌生语言，我们非常需要一款能够帮助初学者轻松入门的学习神器。
                            </div>
                            <div>
                                第358期豌豆荚设计奖，我们颁给一款针对多语种学习的零基础入门级App—「鹿老师说外语」（又名：「LingoDeer」），
                                有了它，我吃着火锅、唱着歌，玩儿着就把外语给学了
                                <a target="_blank" href="https://www.wandoujia.com/award/blog/cn.lingodeer"
                                   class="quickLink">&gt;&gt;</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style src="./Recommend.scss" lang="scss"></style>

<script>
    import Constants from "../../../../utils/Constants"

    export default {
        data() {
            return {
                tjPic: Constants.landPagePic.second_rank.tj
            }
        }
    }
</script>

