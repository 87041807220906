<template>
    <div class="about">
        <div class="title">关于我们</div>
        <div class="navArea">
            <el-tabs v-model="activeName" :stretch="isStretch" @tab-click="handleTabClick">
                <el-tab-pane label="团队介绍" name="team">
                    <transition name="fade">
                        <router-view/>
                    </transition>
                </el-tab-pane>
                <el-tab-pane label="教学方法" name="method">
                    <transition name="fade">
                        <router-view/>
                    </transition>
                </el-tab-pane>
                <el-tab-pane label="平台推荐" name="recommend">
                    <transition name="fade">
                        <router-view/>
                    </transition>
                </el-tab-pane>
                <el-tab-pane label="商务合作" name="contact">
                    <transition name="fade">
                        <router-view/>
                    </transition>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import {Tabs, TabPane} from "element-ui";
    import Recommend from "./widgets/Recommend/Recommend";
    import TeamIntro from "./widgets/TeamIntro/TeamIntro";
    import TeachMethod from "./widgets/TeachMethod/TeachMethod";

    export default {
        name: "About",
        data() {
            return {
                activeName: 'team',
                isStretch: false
            };
        },
        components: {
            "el-tabs": Tabs,
            "el-tab-pane": TabPane,
            Recommend,
            TeamIntro,
            TeachMethod
        },
        watch: {
            $route: {
                handler: function(to) {
                    this.activeName = to.path.replace('/aboutus/', '')
                },
                immediate:true
            }
        },
        methods: {
            handleTabClick(tab) {
                this.$router.replace({path: `/aboutus/${tab.name}`})
            }
        }
    }
</script>

<style lang="scss">
    $activeColor: #F7B500;
    .el-tabs__item {
        font-size: 0.16rem;
        letter-spacing: 0.1px;
        color: #999999;
        height: 0.44rem;
        padding: 0 0.29rem !important;

        &.is-active {
            color: $activeColor;
        }

        &:hover {
            color: $activeColor;
        }
    }

    .el-tabs__header{
        margin: 0;
    }

    .el-tabs__active-bar {
        height: 3px;
        border-radius: 10px;
        background-color: $activeColor;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .about {
        max-width: 8.51rem;
        margin: auto;

        .title {
            font-size: 0.26rem;
            font-weight: bold;
            padding: 0.29rem 0;
            color: #333333;
        }

        .navArea {
            font-size: 10px;
        }
    }
</style>